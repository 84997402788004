<template>
  <v-container fluid>

    <v-card class="pa-2">
      <v-chip-group style="width: 100%" filter multiple show-arrows v-model="mesesSelected" active-class="primary--text" >
        <v-chip v-for="(mes, index) in meses" :key="index" :value="mes" >
          {{ mes.name }}
        </v-chip>
      </v-chip-group>
    </v-card>

    <v-card class="d-flex flex-wrap pa-1" style="gap: 10px" v-if="totales.total > 0" >
      <v-alert dense style="width: 350px" class="ma-0" prominent text type="info">
        <h5>Total</h5>
        <v-divider class="mb-1 info" />
        <h3>
          {{ totales.total }} €
        </h3>
      </v-alert>
      <v-alert dense style="width: 350px" class="ma-0" prominent text type="error">
        <h5>No Recuperado</h5>
        <v-divider class="mb-1 error" />
        <h3>
          {{ totales.totalNoRecuperado }} €
        </h3></v-alert>
      <v-alert dense style="width: 350px" class="ma-0" prominent text type="success">
        <h5>Recuperado</h5>
        <v-divider class="mb-1 success" />
        <h3>
          {{ totales.totalRecuperado }} €
        </h3></v-alert>
    </v-card>

    <v-card>
      <v-data-table
        fixed-header
        :items="impagadosMensual"
        :headers="headers"
        show-select
        class="fixed-checkbox"
        item-key="IdCarteraCobro"
        v-model="selected"
        :single-select="false"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        }"
        checkbox-color="secondary"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="impagadosMensual"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.estado="{ item }">
          <status-chip class="mr-2" :value="item.estado" />
          <v-tooltip right>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                label
                small
                text
                :color="item.colorCliente"
                :class="{
                  'pa-1 font-weight-bold': true,
                  'v-btn--active':
                    inlineFilters.Identificador == '=' + item.Identificador,
                }"
                style="min-width: unset"
                @click="
                  inlineFilters.Identificador == '=' + item.Identificador
                    ? $delete(inlineFilters, 'Identificador')
                    : $set(
                        inlineFilters,
                        'Identificador',
                        '=' + item.Identificador
                      )
                "
                >{{ item.iniciales }}</v-btn
              >
            </template>
            {{ item.denominacion }}
          </v-tooltip>
        </template>

        <template v-slot:item.NImpagadosCliente="{ item }">
          <v-chip
            label
            :color="
              getNImpagadosColor(
                ['info', 'warning', 'deep-orange', 'error', '#d00000'],
                item.NImpagadosCliente
              )
            "
            outlined
            small
            class="pa-2 mr-1 v-chip--active"
            >{{ item.NImpagadosCliente }}</v-chip
          >
					<v-tooltip v-if="Number(item.historialImpagos)" bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-icon :color="getNImpagadosColor(
                ['info', 'warning', 'deep-orange', 'error', '#d00000'],
                item.historialImpagos
              )" v-bind="attrs" v-on="on" small>mdi-timer-sand</v-icon>
							</template>
							Historial impagos: {{ item.historialImpagos }}
						</v-tooltip>
        </template>

        <template v-slot:item.info="{ item }">
          <div class="d-flex align-center">
            <v-tooltip v-if="item.situacionContrato != 'Activado'" bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="getSituacionIcon(item.situacionContrato).color"
                  right
                  >{{ getSituacionIcon(item.situacionContrato).icon }}</v-icon
                >
              </template>
              Contrato {{ item.situacionContrato }}
            </v-tooltip>
            <v-tooltip v-if="item.corteEn" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" right color="error darken-2"
                  >mdi-content-cut</v-icon
                >
              </template>
              Corte {{ item.corteEn.toLowerCase() }}
            </v-tooltip>

            <div class="d-flex flex-column ml-1">
              <v-icon
                v-if="item.envioCarta1"
                :color="getSituacionIcon('1ª Carta').color"
                :disabled="!Number(item.carta1)"
                x-small
                >mdi-email-send-outline</v-icon
              >
              <v-icon
                v-if="item.envioCarta2"
                :color="getSituacionIcon('2ª Carta').color"
                :disabled="!Number(item.carta2)"
                x-small
                >mdi-email-send</v-icon
              >
              <v-icon
                v-if="item.envioCarta3"
                :color="getSituacionIcon('Carta de Corte').color"
                :disabled="!Number(item.cartaCorte)"
                x-small
                >mdi-content-cut</v-icon
              >
            </div>

            <v-icon
              v-if="
                Date.now() - parseDate(item.FechaImpagado, true) <
                  1000 * 3600 * 24 * 1.5
              "
              >mdi-circle-small</v-icon
            >
          </div>
        </template>

        <template v-slot:item.diasDesdeVencimiento="{ item }">
          <v-chip
            outlined
            v-if="item.diasDesdeVencimiento >= 0"
            v-text="`${item.diasDesdeVencimiento} días`"
            small
            :class="['v-chip--active']"
            :color="colorChip(item.diasDesdeVencimiento, item)"
          />
          <!-- - {{ item.diasDesdeVencimiento }} días -->
        </template>

        <template v-slot:item.diasDesdeImpagado="{ item }">
          <v-chip
            outlined
            v-if="item.diasDesdeImpagado >= 0"
            v-text="`${item.diasDesdeImpagado} días`"
            small
            :class="['v-chip--active']"
            :color="colorChip(item.diasDesdeImpagado, item)"
          />
          <!-- - {{ item.diasDesdeVencimiento }} días -->
        </template>

        <template v-slot:item.estadoPropio="{ item }">
          <status-chip v-if="item.estadoPropio" :value="item.estadoPropio" 
            @click.stop="$set(inlineFilters, 'estadoPropio', inlineFilters.estadoPropio ? null : item.estadoPropio)"
           />
          <v-tooltip
            v-if="
              item.estadoPropioVencimiento &&
                parseDate(item.estadoPropioVencimiento, true) <= Date.now()
            "
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="warning" right small
                >mdi-alert-outline</v-icon
              >
            </template>
            Fecha superada
          </v-tooltip>
        </template>

        <template v-slot:item.empresa="{ item }">
          <empresa-chip small v-if="item.empresa" :value="item.empresa" />
        </template>

        <template v-slot:item.diferenciaVenImp="{ item }">
          {{ item.diasDesdeVencimiento - item.diasDesdeImpagado }}
        </template>

        <template v-slot:item.estadoPropioFecha="{ item }">
          <span
            v-if="item.estadoPropioFecha"
            v-text="timeAgo(item.estadoPropioFecha)"
          />
        </template>

        <template v-slot:item.CanalAgente="{ item }">
          <strong>{{ item.CanalAgente }}</strong>
        </template>

        <template v-slot:item.FechaFactura="{ item }">
          <span v-text="parseDate(item.FechaFactura)"></span>
        </template>
        <template v-slot:item.FechaCobro="{ item }">
          <span v-text="parseDate(item.FechaCobro)"></span>
        </template>
        <template v-slot:item.FechaComienzoRemesa="{ item }">
          <v-icon
            v-if="
              item.estado == 'Pendiente' &&
                parseDate(item.FechaTransferencia, true) < Date.now()
            "
            color="warning"
            small
            left
            >mdi-alert-outline</v-icon
          >
          <span v-text="parseDate(item.FechaComienzoRemesa)"></span>
        </template>
        <template v-slot:item.FechaTransferencia="{ item }">
          <v-icon
            v-if="
              item.estado == 'Pendiente' &&
                parseDate(item.FechaTransferencia, true) < Date.now()
            "
            color="warning"
            small
            left
            >mdi-alert-outline</v-icon
          >
          <span v-text="parseDate(item.FechaTransferencia)"></span>
        </template>
        <template v-slot:item.FechaVencimiento="{ item }">
          <span v-text="parseDate(item.FechaVencimiento)"></span>
        </template>
        <template v-slot:item.fechaRecuperacion="{ item }">
          <v-tooltip right v-if="item.contarRec == false">
            <template v-slot:activator="{on, attrs}">
              <v-icon left color="warning" v-bind="attrs" v-on="on" >mdi-alert-box-outline</v-icon>
            </template>
            No suma al total
          </v-tooltip>
          <v-chip v-if="item.fechaRecuperacion" color="success" outlined v-text="parseDate(item.fechaRecuperacion)"></v-chip>
        </template>
        <template v-slot:item.estadoPropioVencimiento="{ item }">
          <span v-text="parseDate(item.estadoPropioVencimiento)"></span>
        </template>
        <template v-slot:item.fechaCreacion="{ item }">
          <span v-text="parseDate(item.fechaCreacion)"></span>
        </template>
        <template v-slot:item.FechaLecturaAnteriorXML="{ item }">
          <span v-text="parseDate(item.FechaLecturaAnteriorXML)"></span>
        </template>
        <template v-slot:item.FechaLecturaActualXML="{ item }">
          <span v-text="parseDate(item.FechaLecturaActualXML)"></span>
        </template>
        <template v-slot:item.FechaImpagado="{ item }">
          <v-tooltip right v-if="item.contarDev == false">
            <template v-slot:activator="{on, attrs}">
              <v-icon left color="warning" v-bind="attrs" v-on="on" >mdi-alert-box-outline</v-icon>
            </template>
            No suma al total
          </v-tooltip>
          <v-chip v-if="item.FechaImpagado" color="error" outlined v-text="parseDate(item.FechaImpagado)"></v-chip>
        </template>
        <template v-slot:item.fechaUltimaFraccion="{ item }">
          <span v-text="parseDate(item.fechaUltimaFraccion)"></span>
        </template>
        <template v-slot:item.envioCarta1="{ item }">
          <span v-text="parseDate(item.envioCarta1)"></span>
        </template>
        <template v-slot:item.envioCarta2="{ item }">
          <span v-text="parseDate(item.envioCarta2)"></span>
        </template>
        <template v-slot:item.envioCarta3="{ item }">
          <span v-text="parseDate(item.envioCarta3)"></span>
        </template>
        <template v-slot:item.fechaCorteEstimada="{ item }">
          <span v-text="parseDate(item.fechaCorteEstimada)"></span>
        </template>

        <template v-slot:item.email="{ item }">
          <table-button
            v-if="item.email"
            color="secondary"
            tooltip="Enviar correo"
            :href="`mailto:${item.email}`"
            icon-left="mdi-email-outline"
            >{{ item.email }}</table-button
          >
        </template>

        <template v-slot:item.movil="{ item }">
          <span>{{ item.movil | phone }}</span>
        </template>
        <template v-slot:item.fijo="{ item }">
          <span>{{ item.fijo | phone }}</span>
        </template>

        <template v-slot:item.ImporteNominal="{ item }">
          <span v-text="parseFloat(item.ImporteNominal).toFixed(2) + ' €'" />
        </template>
        <template v-slot:item.ImporteGasto="{ item }">
          <span v-text="`${parseFloat(item.ImporteGasto).toFixed(2)} €`" />
        </template>
        <template v-slot:item.ImporteTotal="{ item }">
          <span
            class="font-weight-bold"
            v-text="`${parseFloat(item.ImporteTotal).toFixed(2)} €`"
          />
        </template>

        <template v-slot:item.tarifa="{ item }">
          <v-chip v-show="item.tarifa" label small v-text="item.tarifa" />
        </template>

        <template v-slot:item.carta1Bool="{ item }">
          <div class="d-flex text-caption">
            <div style="height: unset" v-if="item.envioCarta1">
              <v-icon
                left
                small
                v-if="item.envioCarta1 != null && !item.carta1"
                color="error"
                >mdi-email-remove-outline</v-icon
              >
              <v-icon left small v-else color="success"
                >mdi-email-fast-outline</v-icon
              >
              {{ timeAgo(item.carta1 || item.envioCarta1, false) }}
            </div>
            <v-icon
              left
              small
              v-else-if="item.envioCarta1 === null"
              color="warning"
              >mdi-minus-circle-outline</v-icon
            >
          </div>
        </template>

        <template v-slot:item.carta2Bool="{ item }">
          <div class="d-flex text-caption">
            <div style="height: unset" v-if="item.envioCarta2">
              <v-icon left small v-if="!item.carta2" color="error"
                >mdi-email-remove-outline</v-icon
              >
              <v-icon left small v-else color="success"
                >mdi-email-fast-outline</v-icon
              >
              {{ timeAgo(item.carta2 || item.envioCarta2, false) }}
            </div>
						 <v-icon
              left
              small
              v-else-if="item.envioCarta2 === null"
              color="warning"
              >mdi-minus-circle-outline</v-icon
            >
          </div>
        </template>

        <template v-slot:item.cartaCorteBool="{ item }">
          <div class="d-flex text-caption">
            <div style="height: unset" v-if="item.envioCarta3">
              <v-icon left small v-if="!item.cartaCorte" color="error"
                >mdi-email-remove-outline</v-icon
              >
              <v-icon left small v-else color="success"
                >mdi-email-fast-outline</v-icon
              >
              {{ timeAgo(item.cartaCorte || item.envioCarta3, false) }}
            </div>
						 <v-icon
              left
              small
              v-else-if="item.envioCarta3 === null"
              color="warning"
              >mdi-minus-circle-outline</v-icon
            >
          </div>
        </template>

        <template v-slot:item.esEmpresa="{ item }">
          <v-icon small v-if="item.esEmpresa == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.dptoJuridico="{ item }">
          <v-icon small v-if="item.dptoJuridico == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.CESCE="{ item }">
          <v-icon small v-if="item.CESCE == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-gavel</v-icon
          >
        </template>

        <template v-slot:item.estimada="{ item }">
          <v-icon small v-if="item.estimada == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.amenazaCorteBool="{ item }">
          <v-icon small v-if="item.amenazaCorteBool == '0'" color
            >mdi-minus-circle-outline</v-icon
          >
          <v-icon small v-else color="error darken-2">mdi-content-cut</v-icon>
        </template>

        <template v-slot:item.superior10KW="{ item }">
          <v-icon small v-if="item.superior10KW == '0'" color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon small v-else color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.denominacion="{ item }">
          <span v-if="item.RazonSocialTitular" class="font-weight-bold">
            {{ item.RazonSocialTitular }}
          </span>
          <template v-else>
            <span>{{ item.Apellido1Titular + "&nbsp;" }}</span>
            <span>{{ item.Apellido2Titular }}</span>
            <span class="font-weight-bold">
              {{ "&nbsp;" + item.NombreTitular }}
            </span>
          </template>
        </template>

        <template v-slot:item.ultimoMensaje="{ item }">
          <span
            v-html="(item.ultimoMensaje || '').replace(/<[^>]*>/g, '')"
            class="text-truncate d-inline-block"
            style="max-width: 350px;"
          ></span>
        </template>

        <template v-slot:item.NumeroFactura="{ item }">
          <table-button
              :tooltip="`Ver PDF factura ${ item.estimada ? '(estimada)' : '' }`"
              :disabled="!!pdfId || !item.IdDocumento"
              color="secondary"
              @click.stop="verPDFfactura(item.IdDocumento)"
              :loading="pdfId == item.IdDocumento && pdfId !== null"
              :icon-right="
                item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'
              "
            >
              {{ item.SerieFactura }}
              {{ item.NumeroFactura }}
            </table-button>
        </template>

        <template v-slot:item.NumeroFacturaRoot="{ item }">
            <table-button
              :tooltip="`Ver PDF factura ${ item.estimada ? '(estimada)' : '' }`"
              :disabled="!!pdfId || !item.IdDocumento"
              color="secondary"
              @click.stop="verPDFfactura(item.IdDocumento)"
              :loading="pdfId == item.IdDocumento && pdfId !== null"
              :icon-right="
                item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'
              "
            >
              {{ item.SerieFacturaRoot }}
              {{ item.NumeroFacturaRoot }}
            </table-button>
        </template>

        <template v-slot:item.situacionContrato="{ item }">
          <status-chip :value="item.situacionContrato" />
        </template>

        <template v-slot:item.NumeroFraccion="{ item }">
          <div class="d-flex" v-if="item.NumeroFraccion">
            <v-icon
              :key="i"
              v-for="i in Number(item.NFracciones)"
              :color="
                [
                  'primary darken-3',
                  'primary darken-2',
                  'primary darken-1',
                  'primary',
                ][i - 1]
              "
            >
              {{
                Number(item.NumeroFraccion) >= i
                  ? "mdi-circle"
                  : "mdi-circle-outline"
              }}
            </v-icon>
          </div>
          <status-chip
            v-else-if="Number(item.NFracciones)"
            small
            :value="`Fraccionado ${item.NFracciones} Pagos`"
          ></status-chip>
        </template>

        <template v-slot:item.CodigoContrato="{ item }">
          <table-button
            color="secondary"
            tooltip="Detalles del contrato"
            :to="
              `/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`
            "
            :icon-left="
              item.CodigoContrato > 0 ? 'mdi-lightning-bolt' : 'mdi-fire'
            "
            >{{ Math.abs(Number(item.CodigoContrato)) }}</table-button
          >
        </template>

        <template v-slot:item.Identificador="{ item }">
          <table-button
            color="secondary"
            tooltip="Detalles del cliente"
            :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
            >{{ item.Identificador }}</table-button
          >
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="
                  {
                    tab = 0;
                    detailsId = item.IdCarteraCobro;
                  }
                "
                icon
                color="secondary"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            Ver detalles
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                color="secondary"
                :disabled="!item.tieneMensajes"
                @click="getMessages(item.IdCarteraCobro, item.IdCliente)"
              >
                <v-icon>mdi-note-multiple-outline</v-icon>
              </v-btn>
            </template>
            Ver último mensaje
          </v-tooltip>
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="secondary"
                icon
                :disabled="!!item.avisoAutomatico || item.email == null"
                :loading="sendingAvisoAutomatico == item.IdCarteraCobro"
                @click.stop="sendAvisoAutomatico(item.IdCarteraCobro, item.ImporteTotal)"
              >
                <v-icon>mdi-email-fast-outline</v-icon>
              </v-btn>
            </template>
            Aviso automático
          </v-tooltip>
        </template>

        <template v-slot:item.BancoCobro="{item}">
          <v-chip small outlined label class="v-chip--active" color="grey">
            <v-icon left small>mdi-bank</v-icon>
            {{item.BancoCobro}}
          </v-chip>
        </template>

        <template v-slot:item.BancoCargo="{item}">
          <v-chip small outlined label class="v-chip--active" color="grey lighten-1">
            <v-icon left small>mdi-bank</v-icon>
            {{item.BancoCargo}}
          </v-chip>
        </template>

      </v-data-table>
    </v-card>

    <v-card-actions class="d-flex justify-center ">

      <v-btn large color="primary" @click.stop="exportar">
        <v-icon left>mdi-download</v-icon>
        Exportar
      </v-btn>
      <!-- <v-btn large text>
        Siguiente
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn> -->
    </v-card-actions>

    <!-- <LoadingSteps :hide-overlay="false" v-if="checksPanel" :value="checks" /> -->
  </v-container>
</template>

<script>
import { perColumnFilter, parseDate, jsonToCsv, timeAgo, getSituacionIcon } from "@/utils/index.js";
import { parseImpagados } from "../services/parseImpagados.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    EmpresaChip: () => import("@/components/EmpresaChip.vue"),
    TableButton: () => import("@/components/TableButton.vue"),
    // LoadingSteps: () => import("@/components/LoadingSteps.vue"),
  },
  data() {
    return {
      impagados: [],
      facturas: [],
      listadoMensajes: [],

      pdfId: null,

      minDate: 0,
      mesesSelected: [],

      loading: true,
      inlineFilters: {},
      selected: [],
      headers: [
        { text: "Estado", value: "estado", dataType: "select", cellClass: "pa-2 text-no-wrap p-relative", },
        { text: "Total", value: "ImporteTotal" },
        { text: "", value: "info", filterable: false, sortable: false },
        { text: "Fecha Impagado", value: "FechaImpagado", dataType: "date" },
        { text: "Fecha Recuperado", value: "fechaRecuperacion", dataType: "date" },
        { text: "Fecha Cobro", value: "FechaCobro", dataType: "date", tabFilter: ["historico"], },
        { text: "Fecha Transferencia", value: "FechaTransferencia", dataType: "date", tabFilter: ["fracTransferencia"], },
        { text: "Fecha Remesado", value: "FechaComienzoRemesa", dataType: "date", tabFilter: ["historico", 'frac'], },
        { text: "Final Fraccionamiento", value: "fechaUltimaFraccion", tabFilter: ["frac"], dataType: "date", },
        { text: "Estado (propio)", value: "estadoPropio", dataType: "select", tabFilter: ["actual", "historico"], },
        { text: "Último cambio estado", value: "estadoPropioFecha", dataType: "date", tabFilter: ["actual", "historico"], },
        { text: "> 10 KW", value: "superior10KW", align: "center", dataType: "bool", },
        { text: "Fraccion", value: "NumeroFraccion" },
        { text: "NºDoc Actual", value: "NumeroFactura" },
        { text: "NºDoc Original", value: "NumeroFacturaRoot" },
        { text: "Portal", value: "empresa", dataType: "select", acceso: "VER_TODOS_CONTRATOS", },
        { text: "Agente/Canal", value: "CanalAgente" },
        { text: "SubAgente", value: "SubAgente" },
        { text: "Cliente", value: "denominacion" },
        { text: "Identificador", value: "Identificador" },
        { text: "Dpto. Jurídico", value: "dptoJuridico", dataType: "bool", align: "center", },
        { text: "CESCE", value: "cesce_temp", dataType: "bool", acceso: 'CONTROL_MONITORIO_IMPAGADOS' },
        { text: "Corte estimado", value: "fechaCorteEstimada", dataType: "date", },
        { text: "Amenaza Corte", value: "amenazaCorteBool", dataType: "bool", align: "center", },
        { text: "Estimada", value: "estimada", dataType: "bool", align: "center" },
        { text: "Situacion Contrato", value: "situacionContrato", dataType: "select", },
        { text: "Contrato", value: "CodigoContrato" },
        { text: "Empresa", value: "esEmpresa", dataType: "bool", align: "center", },
        { text: "Movil", value: "movil" },
        { text: "Fijo", value: "fijo" },
        { text: "Email", value: "email" },
        { text: "Fecha Vencimiento original", value: "FechaVencimiento", dataType: "date", },
        { text: "Fecha Vencimiento (propio)", value: "estadoPropioVencimiento", dataType: "date", },
        { text: "Fecha Transferencia", value: "FechaTransferencia", dataType: "date", tabFilter: ["actual"], },
        { text: "Tipo Cobro", value: "TextoTipoCobro", dataType: "select" },
        { text: "Banco Cobro", value: "BancoCobro", dataType: "select" },
        { text: "Banco Cargo", value: "BancoCargo", dataType: "select" },
        { text: "Canal", value: "Canal", dataType: "select" },
        { text: "Fecha factura", value: "FechaFactura", dataType: "date" },
        { text: "Motivo", value: "motivoImpagado" },
        { text: "Tarifa", value: "tarifa", align: "center", dataType: "select", },
      ].map((header) => ({
        class: header.class || "text-no-wrap sticky-header",
        cellClass: header.cellClass || "pa-2 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  methods: {
    parseDate,
    timeAgo,
    getSituacionIcon,
    getImpagados() {
      return new Promise(async (resolve, reject) => {

        const impagados = await Promise.all([
          axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: "GET", params: { token: this.$store.getters.getJwtEmpresa, menuTab: "actual" } }),
          axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: "GET", params: { token: this.$store.getters.getJwtEmpresa, menuTab: "historico" }, }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'fracTransferencia' } }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'frac' } }),
        ]);

        this.impagados = parseImpagados(
          impagados.map((i) => i.data).flat().map(imp => {
            // if (imp.futuro || imp.aplazado)
              imp.FechaImpagado = imp.FechaImpagado || imp.FechaImpagadoOriginal || imp.FechaVencimiento
              // imp.FechaImpagado = '2022-01-01'
            return imp
          }))
        // .filter(
        //   (f) => {          
        //     return f.FechaImpagado &&
        //     (f.NFracciones == null && f.NumeroFraccion == null) ||
        //     (f.NFracciones != null && f.NumeroFraccion > 0)
        //   }
        // );

        this.minDate = this.impagados.reduce((a, b) => { 
          return a <= parseDate(b.FechaImpagado, true) ? a : parseDate(b.FechaImpagado, true); }, Date.now()); 

        // this.maxDate = this.impagados.reduce((a, b) => { return a >= parseDate(b.FechaImpagado, true) ? a : parseDate(b.FechaImpagado, true); }); 

        resolve();
      });
    },
    exportar(items) {
      const getDatatype = (key) =>
        (this.headers.find((h) => h.value == key) || {}).dataType;

      if (!Array.isArray(items))
        items = this.impagadosMensual.filter((con) =>
          Object.entries(con).every(([key, value]) =>
            perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
          )
        );

      const csvHeaders = [
        { key: "FechaFactura", dataType: "date" },
        { key: "FechaImpagado", dataType: "date" },
        { key: "fechaRecuperacion", dataType: "date" },
        { key: "CodigoContrato" },
        { key: "situacionContrato" },
        { key: "FechaVencimiento", dataType: "date" },
        { key: "IBAN" },
        { key: "ImporteTotal", dataType: "number" },
        { key: "motivoImpagado" },
        { key: "SerieFactura", alias: "Serie Factura" },
        { key: "NumeroFactura", alias: "Numero Factura" },
        { key: "Canal" },
        { key: "TextoTipoCobro", alias: "Tipo Cobro" },
        { key: "SerieFacturaRoot", alias: "Serie Factura Original" },
        { key: "NumeroFacturaRoot", alias: "Numero Factura Original" },
        { key: "denominacion", alias: "Cliente" },
        // { key: 'RazonSocialTitular' },
        // { key: 'NombreTitular' },
        // { key: 'Apellido1Titular' },
        // { key: 'Apellido2Titular' },
        { key: "Identificador" },
        { key: "estadoPropio" },
        { key: "estadoPropioFecha", dataType: "date" },
        { key: "estadoPropioVencimiento", dataType: "date" },
        { key: "CanalAgente" },
        { key: "SubAgente" },
      ];

      jsonToCsv(
        items,
        csvHeaders,
        "IMPAGADOS_MENSUAL_" + parseDate(Date.now(), false, false)
      );
    },
  },
  computed: {
    // filter() {
    //   return {
    //     minDate: 0,
    //     maxDate: Date.now()
    //   }
    // },
    impagadosMensual() {
      return this.impagados.filter(imp => {
        return this.mesesSelected.some((fil) => {
          const dev = (parseDate(imp.FechaImpagado, true) <= parseDate(fil.max, true) && parseDate(imp.FechaImpagado, true) >= parseDate(fil.min, true))
          const rec = imp.fechaRecuperacion != null && parseDate(imp.fechaRecuperacion, true) <= parseDate(fil.max, true) && parseDate(imp.fechaRecuperacion, true) >= parseDate(fil.min, true)
          imp.contarDev = imp.contarDev || dev
          imp.contarRec = imp.contarRec || rec
          return dev == true || rec == true 
        })
        }
      )
    },
    totales() {
      let totalRecuperado = 0;
      let totalNoRecuperado = 0;

      for (const imp of this.impagadosMensual) {
        console.log(imp.contarRec, imp.contarDev, imp);
        if ( imp.contarRec )
          totalRecuperado += imp.ImporteTotal
        else if ( imp.contarDev )
          totalNoRecuperado += imp.ImporteTotal
      }

      return {
        totalRecuperado : parseFloat(totalRecuperado).toFixed(2),
        totalNoRecuperado : parseFloat(totalNoRecuperado).toFixed(2),
        total : parseFloat(totalRecuperado + totalNoRecuperado).toFixed(2)
      }

    },
    meses() {
      if (this.minDate == 0) return []
      let min = new Date(this.minDate)
      min.setDate(1)

      const meses = []

      const mesesNombres = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      while (min.getTime() <= Date.now()) {
        let firstDay = new Date(min.getFullYear(), min.getMonth(), 1);
        let lastDay = new Date(min.getFullYear(), min.getMonth() + 1, 0);

        meses.push({
          min: firstDay,
          max: lastDay,
          name : `${mesesNombres[min.getMonth()]} ${min.getFullYear()}`,
        })

        min.setMonth( min.getMonth() + 1 )
      }
      
      return meses
    }
  },
  async mounted() {
    await this.getImpagados();
    this.loading = false;
  },
  // watch: {
  //   loading(val) {
  //     if (val) this.checksPanel = true;
  //     else {
  //       setTimeout(() => {
  //         this.checksPanel = false;
  //         this.checks = {};
  //       }, 1000);
  //     }
  //   },
  // },
};
</script>
