var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-2"},[_c('v-chip-group',{staticStyle:{"width":"100%"},attrs:{"filter":"","multiple":"","show-arrows":"","active-class":"primary--text"},model:{value:(_vm.mesesSelected),callback:function ($$v) {_vm.mesesSelected=$$v},expression:"mesesSelected"}},_vm._l((_vm.meses),function(mes,index){return _c('v-chip',{key:index,attrs:{"value":mes}},[_vm._v(" "+_vm._s(mes.name)+" ")])}),1)],1),(_vm.totales.total > 0)?_c('v-card',{staticClass:"d-flex flex-wrap pa-1",staticStyle:{"gap":"10px"}},[_c('v-alert',{staticClass:"ma-0",staticStyle:{"width":"350px"},attrs:{"dense":"","prominent":"","text":"","type":"info"}},[_c('h5',[_vm._v("Total")]),_c('v-divider',{staticClass:"mb-1 info"}),_c('h3',[_vm._v(" "+_vm._s(_vm.totales.total)+" € ")])],1),_c('v-alert',{staticClass:"ma-0",staticStyle:{"width":"350px"},attrs:{"dense":"","prominent":"","text":"","type":"error"}},[_c('h5',[_vm._v("No Recuperado")]),_c('v-divider',{staticClass:"mb-1 error"}),_c('h3',[_vm._v(" "+_vm._s(_vm.totales.totalNoRecuperado)+" € ")])],1),_c('v-alert',{staticClass:"ma-0",staticStyle:{"width":"350px"},attrs:{"dense":"","prominent":"","text":"","type":"success"}},[_c('h5',[_vm._v("Recuperado")]),_c('v-divider',{staticClass:"mb-1 success"}),_c('h3',[_vm._v(" "+_vm._s(_vm.totales.totalRecuperado)+" € ")])],1)],1):_vm._e(),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.impagadosMensual,"headers":_vm.headers,"show-select":"","item-key":"IdCarteraCobro","single-select":false,"loading":_vm.loading,"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        },"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
        var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.impagadosMensual},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.estado",fn:function(ref){
        var item = ref.item;
return [_c('status-chip',{staticClass:"mr-2",attrs:{"value":item.estado}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:{
                  'pa-1 font-weight-bold': true,
                  'v-btn--active':
                    _vm.inlineFilters.Identificador == '=' + item.Identificador,
                },staticStyle:{"min-width":"unset"},attrs:{"label":"","small":"","text":"","color":item.colorCliente},on:{"click":function($event){_vm.inlineFilters.Identificador == '=' + item.Identificador
                    ? _vm.$delete(_vm.inlineFilters, 'Identificador')
                    : _vm.$set(
                        _vm.inlineFilters,
                        'Identificador',
                        '=' + item.Identificador
                      )}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.iniciales))])]}}],null,true)},[_vm._v(" "+_vm._s(item.denominacion)+" ")])]}},{key:"item.NImpagadosCliente",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{staticClass:"pa-2 mr-1 v-chip--active",attrs:{"label":"","color":_vm.getNImpagadosColor(
                ['info', 'warning', 'deep-orange', 'error', '#d00000'],
                item.NImpagadosCliente
              ),"outlined":"","small":""}},[_vm._v(_vm._s(item.NImpagadosCliente))]),(Number(item.historialImpagos))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
              var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.getNImpagadosColor(
                ['info', 'warning', 'deep-orange', 'error', '#d00000'],
                item.historialImpagos
              ),"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-timer-sand")])]}}],null,true)},[_vm._v(" Historial impagos: "+_vm._s(item.historialImpagos)+" ")]):_vm._e()]}},{key:"item.info",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.situacionContrato != 'Activado')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
              var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.getSituacionIcon(item.situacionContrato).color,"right":""}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getSituacionIcon(item.situacionContrato).icon))])]}}],null,true)},[_vm._v(" Contrato "+_vm._s(item.situacionContrato)+" ")]):_vm._e(),(item.corteEn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":"","color":"error darken-2"}},'v-icon',attrs,false),on),[_vm._v("mdi-content-cut")])]}}],null,true)},[_vm._v(" Corte "+_vm._s(item.corteEn.toLowerCase())+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column ml-1"},[(item.envioCarta1)?_c('v-icon',{attrs:{"color":_vm.getSituacionIcon('1ª Carta').color,"disabled":!Number(item.carta1),"x-small":""}},[_vm._v("mdi-email-send-outline")]):_vm._e(),(item.envioCarta2)?_c('v-icon',{attrs:{"color":_vm.getSituacionIcon('2ª Carta').color,"disabled":!Number(item.carta2),"x-small":""}},[_vm._v("mdi-email-send")]):_vm._e(),(item.envioCarta3)?_c('v-icon',{attrs:{"color":_vm.getSituacionIcon('Carta de Corte').color,"disabled":!Number(item.cartaCorte),"x-small":""}},[_vm._v("mdi-content-cut")]):_vm._e()],1),(
                Date.now() - _vm.parseDate(item.FechaImpagado, true) <
                  1000 * 3600 * 24 * 1.5
              )?_c('v-icon',[_vm._v("mdi-circle-small")]):_vm._e()],1)]}},{key:"item.diasDesdeVencimiento",fn:function(ref){
              var item = ref.item;
return [(item.diasDesdeVencimiento >= 0)?_c('v-chip',{class:['v-chip--active'],attrs:{"outlined":"","small":"","color":_vm.colorChip(item.diasDesdeVencimiento, item)},domProps:{"textContent":_vm._s(((item.diasDesdeVencimiento) + " días"))}}):_vm._e()]}},{key:"item.diasDesdeImpagado",fn:function(ref){
              var item = ref.item;
return [(item.diasDesdeImpagado >= 0)?_c('v-chip',{class:['v-chip--active'],attrs:{"outlined":"","small":"","color":_vm.colorChip(item.diasDesdeImpagado, item)},domProps:{"textContent":_vm._s(((item.diasDesdeImpagado) + " días"))}}):_vm._e()]}},{key:"item.estadoPropio",fn:function(ref){
              var item = ref.item;
return [(item.estadoPropio)?_c('status-chip',{attrs:{"value":item.estadoPropio},on:{"click":function($event){$event.stopPropagation();return _vm.$set(_vm.inlineFilters, 'estadoPropio', _vm.inlineFilters.estadoPropio ? null : item.estadoPropio)}}}):_vm._e(),(
              item.estadoPropioVencimiento &&
                _vm.parseDate(item.estadoPropioVencimiento, true) <= Date.now()
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","right":"","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-outline")])]}}],null,true)},[_vm._v(" Fecha superada ")]):_vm._e()]}},{key:"item.empresa",fn:function(ref){
            var item = ref.item;
return [(item.empresa)?_c('empresa-chip',{attrs:{"small":"","value":item.empresa}}):_vm._e()]}},{key:"item.diferenciaVenImp",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.diasDesdeVencimiento - item.diasDesdeImpagado)+" ")]}},{key:"item.estadoPropioFecha",fn:function(ref){
            var item = ref.item;
return [(item.estadoPropioFecha)?_c('span',{domProps:{"textContent":_vm._s(_vm.timeAgo(item.estadoPropioFecha))}}):_vm._e()]}},{key:"item.CanalAgente",fn:function(ref){
            var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.CanalAgente))])]}},{key:"item.FechaFactura",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaFactura))}})]}},{key:"item.FechaCobro",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaCobro))}})]}},{key:"item.FechaComienzoRemesa",fn:function(ref){
            var item = ref.item;
return [(
              item.estado == 'Pendiente' &&
                _vm.parseDate(item.FechaTransferencia, true) < Date.now()
            )?_c('v-icon',{attrs:{"color":"warning","small":"","left":""}},[_vm._v("mdi-alert-outline")]):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaComienzoRemesa))}})]}},{key:"item.FechaTransferencia",fn:function(ref){
            var item = ref.item;
return [(
              item.estado == 'Pendiente' &&
                _vm.parseDate(item.FechaTransferencia, true) < Date.now()
            )?_c('v-icon',{attrs:{"color":"warning","small":"","left":""}},[_vm._v("mdi-alert-outline")]):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaTransferencia))}})]}},{key:"item.FechaVencimiento",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaVencimiento))}})]}},{key:"item.fechaRecuperacion",fn:function(ref){
            var item = ref.item;
return [(item.contarRec == false)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-box-outline")])]}}],null,true)},[_vm._v(" No suma al total ")]):_vm._e(),(item.fechaRecuperacion)?_c('v-chip',{attrs:{"color":"success","outlined":""},domProps:{"textContent":_vm._s(_vm.parseDate(item.fechaRecuperacion))}}):_vm._e()]}},{key:"item.estadoPropioVencimiento",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.estadoPropioVencimiento))}})]}},{key:"item.fechaCreacion",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.fechaCreacion))}})]}},{key:"item.FechaLecturaAnteriorXML",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaLecturaAnteriorXML))}})]}},{key:"item.FechaLecturaActualXML",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaLecturaActualXML))}})]}},{key:"item.FechaImpagado",fn:function(ref){
            var item = ref.item;
return [(item.contarDev == false)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-box-outline")])]}}],null,true)},[_vm._v(" No suma al total ")]):_vm._e(),(item.FechaImpagado)?_c('v-chip',{attrs:{"color":"error","outlined":""},domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaImpagado))}}):_vm._e()]}},{key:"item.fechaUltimaFraccion",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.fechaUltimaFraccion))}})]}},{key:"item.envioCarta1",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.envioCarta1))}})]}},{key:"item.envioCarta2",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.envioCarta2))}})]}},{key:"item.envioCarta3",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.envioCarta3))}})]}},{key:"item.fechaCorteEstimada",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.fechaCorteEstimada))}})]}},{key:"item.email",fn:function(ref){
            var item = ref.item;
return [(item.email)?_c('table-button',{attrs:{"color":"secondary","tooltip":"Enviar correo","href":("mailto:" + (item.email)),"icon-left":"mdi-email-outline"}},[_vm._v(_vm._s(item.email))]):_vm._e()]}},{key:"item.movil",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("phone")(item.movil)))])]}},{key:"item.fijo",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("phone")(item.fijo)))])]}},{key:"item.ImporteNominal",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(parseFloat(item.ImporteNominal).toFixed(2) + ' €')}})]}},{key:"item.ImporteGasto",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(((parseFloat(item.ImporteGasto).toFixed(2)) + " €"))}})]}},{key:"item.ImporteTotal",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(((parseFloat(item.ImporteTotal).toFixed(2)) + " €"))}})]}},{key:"item.tarifa",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.tarifa),expression:"item.tarifa"}],attrs:{"label":"","small":""},domProps:{"textContent":_vm._s(item.tarifa)}})]}},{key:"item.carta1Bool",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex text-caption"},[(item.envioCarta1)?_c('div',{staticStyle:{"height":"unset"}},[(item.envioCarta1 != null && !item.carta1)?_c('v-icon',{attrs:{"left":"","small":"","color":"error"}},[_vm._v("mdi-email-remove-outline")]):_c('v-icon',{attrs:{"left":"","small":"","color":"success"}},[_vm._v("mdi-email-fast-outline")]),_vm._v(" "+_vm._s(_vm.timeAgo(item.carta1 || item.envioCarta1, false))+" ")],1):(item.envioCarta1 === null)?_c('v-icon',{attrs:{"left":"","small":"","color":"warning"}},[_vm._v("mdi-minus-circle-outline")]):_vm._e()],1)]}},{key:"item.carta2Bool",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex text-caption"},[(item.envioCarta2)?_c('div',{staticStyle:{"height":"unset"}},[(!item.carta2)?_c('v-icon',{attrs:{"left":"","small":"","color":"error"}},[_vm._v("mdi-email-remove-outline")]):_c('v-icon',{attrs:{"left":"","small":"","color":"success"}},[_vm._v("mdi-email-fast-outline")]),_vm._v(" "+_vm._s(_vm.timeAgo(item.carta2 || item.envioCarta2, false))+" ")],1):(item.envioCarta2 === null)?_c('v-icon',{attrs:{"left":"","small":"","color":"warning"}},[_vm._v("mdi-minus-circle-outline")]):_vm._e()],1)]}},{key:"item.cartaCorteBool",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex text-caption"},[(item.envioCarta3)?_c('div',{staticStyle:{"height":"unset"}},[(!item.cartaCorte)?_c('v-icon',{attrs:{"left":"","small":"","color":"error"}},[_vm._v("mdi-email-remove-outline")]):_c('v-icon',{attrs:{"left":"","small":"","color":"success"}},[_vm._v("mdi-email-fast-outline")]),_vm._v(" "+_vm._s(_vm.timeAgo(item.cartaCorte || item.envioCarta3, false))+" ")],1):(item.envioCarta3 === null)?_c('v-icon',{attrs:{"left":"","small":"","color":"warning"}},[_vm._v("mdi-minus-circle-outline")]):_vm._e()],1)]}},{key:"item.esEmpresa",fn:function(ref){
            var item = ref.item;
return [(item.esEmpresa == '0')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])]}},{key:"item.dptoJuridico",fn:function(ref){
            var item = ref.item;
return [(item.dptoJuridico == '0')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])]}},{key:"item.CESCE",fn:function(ref){
            var item = ref.item;
return [(item.CESCE == '0')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-gavel")])]}},{key:"item.estimada",fn:function(ref){
            var item = ref.item;
return [(item.estimada == '0')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])]}},{key:"item.amenazaCorteBool",fn:function(ref){
            var item = ref.item;
return [(item.amenazaCorteBool == '0')?_c('v-icon',{attrs:{"small":"","color":""}},[_vm._v("mdi-minus-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"error darken-2"}},[_vm._v("mdi-content-cut")])]}},{key:"item.superior10KW",fn:function(ref){
            var item = ref.item;
return [(item.superior10KW == '0')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])]}},{key:"item.denominacion",fn:function(ref){
            var item = ref.item;
return [(item.RazonSocialTitular)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.RazonSocialTitular)+" ")]):[_c('span',[_vm._v(_vm._s(item.Apellido1Titular + " "))]),_c('span',[_vm._v(_vm._s(item.Apellido2Titular))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(" " + item.NombreTitular)+" ")])]]}},{key:"item.ultimoMensaje",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-inline-block",staticStyle:{"max-width":"350px"},domProps:{"innerHTML":_vm._s((item.ultimoMensaje || '').replace(/<[^>]*>/g, ''))}})]}},{key:"item.NumeroFactura",fn:function(ref){
            var item = ref.item;
return [_c('table-button',{attrs:{"tooltip":("Ver PDF factura " + (item.estimada ? '(estimada)' : '')),"disabled":!!_vm.pdfId || !item.IdDocumento,"color":"secondary","loading":_vm.pdfId == item.IdDocumento && _vm.pdfId !== null,"icon-right":item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'},on:{"click":function($event){$event.stopPropagation();return _vm.verPDFfactura(item.IdDocumento)}}},[_vm._v(" "+_vm._s(item.SerieFactura)+" "+_vm._s(item.NumeroFactura)+" ")])]}},{key:"item.NumeroFacturaRoot",fn:function(ref){
                  var item = ref.item;
return [_c('table-button',{attrs:{"tooltip":("Ver PDF factura " + (item.estimada ? '(estimada)' : '')),"disabled":!!_vm.pdfId || !item.IdDocumento,"color":"secondary","loading":_vm.pdfId == item.IdDocumento && _vm.pdfId !== null,"icon-right":item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'},on:{"click":function($event){$event.stopPropagation();return _vm.verPDFfactura(item.IdDocumento)}}},[_vm._v(" "+_vm._s(item.SerieFacturaRoot)+" "+_vm._s(item.NumeroFacturaRoot)+" ")])]}},{key:"item.situacionContrato",fn:function(ref){
                  var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.situacionContrato}})]}},{key:"item.NumeroFraccion",fn:function(ref){
                  var item = ref.item;
return [(item.NumeroFraccion)?_c('div',{staticClass:"d-flex"},_vm._l((Number(item.NFracciones)),function(i){return _c('v-icon',{key:i,attrs:{"color":[
                  'primary darken-3',
                  'primary darken-2',
                  'primary darken-1',
                  'primary' ][i - 1]}},[_vm._v(" "+_vm._s(Number(item.NumeroFraccion) >= i ? "mdi-circle" : "mdi-circle-outline")+" ")])}),1):(Number(item.NFracciones))?_c('status-chip',{attrs:{"small":"","value":("Fraccionado " + (item.NFracciones) + " Pagos")}}):_vm._e()]}},{key:"item.CodigoContrato",fn:function(ref){
                var item = ref.item;
return [_c('table-button',{attrs:{"color":"secondary","tooltip":"Detalles del contrato","to":("/contratos/detalles?codigoContrato=" + (item.CodigoContrato) + "&idContrato=" + (item.IdContrato)),"icon-left":item.CodigoContrato > 0 ? 'mdi-lightning-bolt' : 'mdi-fire'}},[_vm._v(_vm._s(Math.abs(Number(item.CodigoContrato))))])]}},{key:"item.Identificador",fn:function(ref){
                var item = ref.item;
return [_c('table-button',{attrs:{"color":"secondary","tooltip":"Detalles del cliente","to":("/clientes/detalles?idCliente=" + (item.IdCliente))}},[_vm._v(_vm._s(item.Identificador))])]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){{
                    _vm.tab = 0;
                    _vm.detailsId = item.IdCarteraCobro;
                  }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_vm._v(" Ver detalles ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary","disabled":!item.tieneMensajes},on:{"click":function($event){return _vm.getMessages(item.IdCarteraCobro, item.IdCliente)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-note-multiple-outline")])],1)]}}],null,true)},[_vm._v(" Ver último mensaje ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":"","disabled":!!item.avisoAutomatico || item.email == null,"loading":_vm.sendingAvisoAutomatico == item.IdCarteraCobro},on:{"click":function($event){$event.stopPropagation();return _vm.sendAvisoAutomatico(item.IdCarteraCobro, item.ImporteTotal)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-fast-outline")])],1)]}}],null,true)},[_vm._v(" Aviso automático ")])]}},{key:"item.BancoCobro",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip--active",attrs:{"small":"","outlined":"","label":"","color":"grey"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-bank")]),_vm._v(" "+_vm._s(item.BancoCobro)+" ")],1)]}},{key:"item.BancoCargo",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip--active",attrs:{"small":"","outlined":"","label":"","color":"grey lighten-1"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-bank")]),_vm._v(" "+_vm._s(item.BancoCargo)+" ")],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"d-flex justify-center "},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.exportar($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Exportar ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }